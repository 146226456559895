<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet>
          <template v-slot:title>
            {{ $t("timeTable.title") }}
          </template>
          <template v-slot:toolbar>
            <b-form-datepicker 
                id="example-datepicker" 
                v-model="filter.date" class="mb-2" 
                @context="changeDate"
                locale="ru"
                start-weekday="1"
                :date-disabled-fn="dateDisabled"
                :disabled="editMode">
            </b-form-datepicker>
          </template>
          <template v-slot:body>
            <div class="alert alert-danger">
              Дорогие учителя.
              Если есть вопросы сразу обращайтесь к заучу или ответвенному по школе за дневник.
              По ссылке можете найти видеоинструкцию
              <a href="https://www.youtube.com/watch?v=Nz3dB479aB4&list=PL7rW8E5_3asAGTuOxNkLFMfCoGbv5-zDZ&index=1"
                 target="_blank" style="font-style: italic; color: black; 
                   text-decoration: underline; font-weight: bold">Инструкция</a>
            </div>
            
            <div class="row">
              <div class="col-lg-12">
                <b-button type="button" 
                          @click="enableEditMode" 
                          variant="outline-primary"
                          :class="{'kt-spinner kt-spinner--light disabled':loading }"
                          :disabled="loading"
                          v-if="showEditButton"
                >
                  <i class="fa fa-pen">Редактировать</i>
                </b-button>
                <b-button type="button"
                          @click="additionalLesson"
                          variant="outline-primary"
                          :class="{'kt-spinner kt-spinner--light disabled':loading }"
                          :disabled="loading"
                          style="margin-left: 10px"
                          v-if="editMode"
                >
                  <i class="fa fa-plus">Дополнительный урок</i>
                </b-button>
              </div>
            </div>
            <div v-for="item in data" :key="item.group.id" style="overflow-x: auto">
              <div class="group-name"><span>{{ item.group.groupName }}</span>
                <button class="btn btn-primary"
                        v-if="showEditButton && showCopyButtonFromPrevWeek(item.group.id)"
                        @click="copyFromPrevWeek(item.group.id)"
                        :class="{'kt-spinner kt-spinner--light disabled':loading }"
                        :disabled="loading"
                >
                  {{ $t("timeTable.copyFromPrevWeek") }}
                </button>
                <button class="btn btn-primary"
                        v-if="showEditButton && showCopyButtonFromPrevWeek(item.group.id)"
                        @click="copyFromNextWeek(item.group.id)"
                        :class="{'kt-spinner kt-spinner--light disabled':loading }"
                        :disabled="loading"
                >
                  {{ $t("timeTable.copyFromNextWeek") }}
                </button>
              </div>
              
              <div :reDrawTable="reDrawTable" class="row undistributed-lessons-container" 
                   v-if="editMode">
                <div class="col-lg-12">
                  <h6>Уроки</h6>
                </div>
                <div class="col-lg-12">
                  <div v-for="(undistributedLesson, k) in getSubjectsPerWeekByGroup(item.group.id)" :key="k"
                       class="undistributed-lessons rounded-lg"
                       :style="{'background-color': undistributedLesson.color}">
                      
                    <div>
                      {{undistributedLesson.text}}
                    </div>
                    <div>
                      {{undistributedLesson.teacherName}}
                    </div>
                    <div>
                      {{undistributedLesson.roomNumber}}
                    </div>
                    <div>
                      <button class="btn btn-icon btn-danger"
                              @click="createByMoveItemModal(undistributedLesson, item.group.id)"
                              v-if="editMode">
                        <i class="fa fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              
              <table class="table table-bordered" id="timeTable-table">
                <thead class="thead-light">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">
                    {{ $t("timeTable.day.monday") }}
                    <span class="column-date">
                      {{dates[1]}}
                    </span>
                  </th>
                  <th class="text-left">
                    {{ $t("timeTable.day.tuesday") }}
                    <span class="column-date">
                      {{dates[2]}}
                    </span>
                  </th>
                  <th class="text-left">
                    {{ $t("timeTable.day.wednesday") }}
                    <span class="column-date">
                      {{dates[3]}}
                    </span>
                  </th>
                  <th class="text-left">
                    {{ $t("timeTable.day.thursday") }}
                    <span class="column-date">
                      {{dates[4]}}
                    </span>
                  </th>
                  <th class="text-left">
                    {{ $t("timeTable.day.friday") }}
                    <span class="column-date">
                      {{dates[5]}}
                    </span>
                  </th>
                  <th class="text-left">
                    {{ $t("timeTable.day.saturday") }}
                    <span class="column-date">
                      {{dates[6]}}
                    </span>
                  </th>
                </tr>
                </thead>
                <tbody :reDrawTable="reDrawTable">
                <tr v-for="dayItem in dayItems" :key="dayItem">
                  <td>{{ dayItem }}</td>
                  <td v-for="day in days" :key="day"
                      v-on:drop="drop"
                      v-on:dragover="allowDrop"
                      :group-id="item.group.id"
                      :day="day"
                      :day-item="dayItem"
                  >
                    <div v-for="(item1, k) in form[item.group.id][day][dayItem]" :key="k" 
                         :draggable="true"
                         :group-id="item.group.id"
                         :day="day"
                         :day-item="dayItem"
                         :id="item.group.id + '_' + day + '_'+ dayItem + '_'+ k"
                         :index="k"
                         :is-additional="item1.isAdditional"
                         @dragstart="dragStart($event)"
                         class="time-table-item rounded-lg"
                         :class="{'additional-lesson':item1.isAdditional }"
                         :style="{'background-color': colors[item1.subjectId]}">
                      <div>
                        <button @click.self="removeItem(item.group.id, day, dayItem, k)"
                          class="btn btn-danger time-table-item-button"
                                v-if="editMode && showDeleteButton">Удалить</button>
                        
                        <button @click.self="editItem(item.group.id, day, dayItem, k)"
                                class="btn btn-danger time-table-item-button"
                                v-if="editMode">Переместить</button>
                      </div>
                      
                      {{ subjectNames[item1.subjectId] }} <i v-if="item1.isAdditional=== true">(Доп)</i>
                      <div>Учитель {{item1.teacherName}}</div>
                      <div>Кабинет {{item1.roomNumber}}</div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div>
<!--              <b-button type="submit" @click="onSubmit" variant="primary" id="submitButton"
                        :class="{'kt-spinner kt-spinner&#45;&#45;light disabled':loading }"
                        :disabled="loading"
                        v-if="editMode"
              >
                {{ $t("common.save") }}
              </b-button>-->
              <b-button type="submit"
                        :disabled="loading"
                        v-if="editMode"
                        @click="cancelEdit"
                        variant="danger"
              >
                Закрыть редактирование
              </b-button>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>

    <div>
      <b-modal id="moveItem" ref="moveItem-modal" @hide="forceReDraw">
        <template v-slot:modal-title>
          {{ $t("common.move") }}
        </template>
        <div style="height: 400px; overflow-y: auto">
          <div>
            {{moveItem.subjectName}}
          </div>
          <div>
            Учитель - {{moveItem.teacherName}}
          </div>
          <div>
            Кабинет - {{moveItem.roomNumber}}
          </div>
          <b-form>
            <b-form-group>
              <template v-slot:label>
                {{ $t("timeTable.moveDay") }}
              </template>
              <b-form-select
                  id="input-2"
                  v-model="moveItem.day"
                  :options="dayOptions"
                  required
              ></b-form-select>
            </b-form-group>
            <b-form-group id="input-group-5" label-for="input-5">
              <template v-slot:label>
                {{ $t("timeTable.moveDayItem") }}
              </template>
              <b-form-select
                  id="input-2"
                  v-model="moveItem.dayItem"
                  :options="dayItemOptions"
                  required
              ></b-form-select>
            </b-form-group>
          </b-form>
        </div>

        <template v-slot:modal-footer>
          <b-button type="reset" @click="moveButtonPressed" variant="success"
          >{{
              $t("common.move")
            }}
          </b-button>          
          <b-button type="reset" @click="closeMoveModal" variant="danger"
          >{{
              $t("common.close")
            }}
          </b-button>
        </template>
      </b-modal>

      <b-modal id="createByMoveItem" ref="createByMoveItem-modal" @hide="forceReDraw">
        <template v-slot:modal-title>
          {{ $t("common.move") }}
        </template>
        <div style="height: 400px; overflow-y: auto">
          <div>
            {{createByMoveItem.subjectName}}
          </div>
          <div>
            Учитель - {{createByMoveItem.teacherName}}
          </div>  
          <div>
            Кабинет - {{createByMoveItem.roomNumber}}
          </div>
          <b-form>
            <b-form-group id="input-group-5" label-for="input-5">
              <template v-slot:label>
                {{ $t("timeTable.moveDay") }}
              </template>
              <b-form-select
                  id="input-2"
                  v-model="createByMoveItem.day"
                  :options="dayOptions"
                  required
              ></b-form-select>
            </b-form-group>
            <b-form-group id="input-group-5" label-for="input-5">
              <template v-slot:label>
                {{ $t("timeTable.moveDayItem") }}
              </template>
              <b-form-select
                  id="input-2"
                  v-model="createByMoveItem.dayItem"
                  :options="dayItemOptions"
                  required
              ></b-form-select>
            </b-form-group>
          </b-form>
        </div>

        <template v-slot:modal-footer>
          <b-button type="reset" @click="createByMoveButtonPressed" variant="success"
          >{{
              $t("common.move")
            }}
          </b-button>          <b-button type="reset" @click="closeCreateByMoveModal" variant="danger"
        >{{
            $t("common.close")
          }}
        </b-button>
        </template>
      </b-modal>

      <b-modal id="createAdditionalLesson" ref="createAdditionalLesson-modal" @hide="forceReDraw">
        <template v-slot:modal-title>
          Дополнительный урок
        </template>
        <div style="height: 400px; overflow-y: auto">
<!--          <div>
            {{createAdditional.subjectName}}
          </div>
          <div>
            Учитель - {{createAdditional.teacherName}}
          </div>
          <div>
            Кабинет - {{createAdditional.roomNumber}}
          </div>-->
          <b-form>
            <b-form-group>
              <template v-slot:label>
                Предмет
              </template>
              <b-form-select
                  v-model="createAdditional.subjectId"
                  :options="subjects"
                  required
              ></b-form-select>
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ $t("timeTable.moveDay") }}
              </template>
              <b-form-select
                  v-model="createAdditional.day"
                  :options="dayOptions"
                  required
              ></b-form-select>
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ $t("timeTable.moveDayItem") }}
              </template>
              <b-form-select
                  v-model="createAdditional.dayItem"
                  :options="dayItemOptions"
                  required
              ></b-form-select>
            </b-form-group>
          </b-form>
        </div>

        <template v-slot:modal-footer>
          <b-button type="reset" @click="createAdditionalButtonPressed" variant="success"
          >
            Добавить
          </b-button>
          <b-button type="reset" @click="closeCreateAdditionalModal" variant="danger"
        >{{
            $t("common.close")
          }}
        </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<style>

.column-date{
  font-weight: bold;
  white-space: pre;
}

#submitButton {
  margin-right: 10px;
}

/*table, th, td {
  border: 1px solid black;
}*/

.time-table-item {
  width: 94%;  
  background: #1BC5BD;
  color: #FFFFFF;
  margin: 4px;
  padding: 15px 5px;
  cursor: pointer;
}

.group-name {
  line-height: 35px;
  background: #84D49E;
  color: #FFFFFF;
  /*padding: 10px 15px;*/
  margin: 15px 0 0 0;
  position: relative;

  text-align: right;
  min-height: 45px;
}

.group-name>span{
  position: absolute;
  left: 10px;
}

.group-name > button {
  margin-right: 10px;
}

.time-table-item-button{
  width: 100%;
  margin-bottom: 5px;
}
.undistributed-lessons-container{
  margin: 5px 0;
}
.undistributed-lessons-container>h6{
  
}
.undistributed-lessons{
  background: #1BC522;
  width: 200px;
  float: left;
  margin: 5px 10px 0 0 ;
  padding: 10px;  
}

.undistributed-lessons>div{
  color: white;
}

.undistributed-lessons>div>button{
  width: 100%;
}

.additional-lesson{
  border: 3px solid red;
}

@media (max-width: 767px) {
/*  #timeTable-table{
    display: none;
  }*/
  
  #timeTable-table td, #timeTable-table th{
    padding: 5px 1px !important;
    font-size: 10px !important;
    
  }

  #timeTable-table button{
    padding: 3px;
    margin: 5px 1px !important;
    font-size: 10px !important;
    word-wrap: break-word;
    /*max-width: 50px !important;*/
  }

  .time-table-item{
    margin: 1px !important;
    width: auto !important;
    height: auto !important;
    line-height: 15px;
    word-wrap: break-word;
    /*max-width: 50px !important;*/
  }

}
</style>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";
import {mapGetters} from "vuex";

export default {
  name: "timeTableForGroup",
  components: {
    KTPortlet
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUser"
    ])
  },
  data() {
    return {
      filter: {
        educationYear: 2024,
        level: 1,
        shift: 1,
        date: DictionariesService.startOfWeek(new Date()),
        groupId: 0,
      },
      vv: [],
      data: [],
      groups: [],
      groupTimeTableItems: [],
      currentDay: 1,
      currentDayItem: 1,
      currentGroup: 0,
      dayItems: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      days: [1, 2, 3, 4, 5, 6],
      form: {},
      subjects: [],
      subjectsPerWeek: [],
      subjectsPerWeekByGroup: {},
      subjectNames: {},
      groupId: null,
      groupInfo: {},
      levels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      educationYears: [{text: "2022-2023", value: 2022}, { text: "2023-2024", value: 2023 }],
      shifts: [{text: "Первая", value: 1}, {text: "Вторая", value: 2}, { text: "Третья", value: 3 }],
      watch: {
        form: {
          handler: function () {
          },
          deep: true
        }
      },
      reDrawTable: 0,
      toDelete: [],
      loading:true,
      dates: {},
      moveItem:{
        groupId:0,
        day:0,
        dayItem:0,
        index:0,
        teacherName:'',
        roomNumber:'',
        subjectName:'',        
      },
      createByMoveItem:{
        groupId:0,
        day:0,
        dayItem:0,
        index:0,
        teacherName:'',
        roomNumber:'',
        subjectName:'',
      },
      createAdditional:{
        groupId:0,
        day:0,
        dayItem:0,
        index:0,
        teacherName:'',
        roomNumber:'',
        subjectName:'',
      },
      createByMoveItemLesson:null,
      dayOptions:DictionariesService.getDayOptions(),
      dayItemOptions:DictionariesService.getDayItemOptions(),
      usedItems:{},
      showDay:null,
      colors:{},
      editMode: false,
      showEditButton: false,
      showDeleteButton: false,
    };
  },
  mounted() {
    this.filter.groupId = this.$route.params.id;
    this.filterData();

    ApiService.querySecured("access/getUserAccessToTimeTable", {
      params: {groupId: this.filter.groupId}
    })
        .then(({data}) => {
          this.showEditButton = data.accessEditing;
          this.showDeleteButton = data.accessDeleting;
        }).catch(({response}) => {
      console.log(response);
    });
  },
  methods: {
    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      return weekday !== 1;
    },
    removeItem(group, day, dayItem, k) {
      if(!confirm('Удалить урок?')){
        return;
      }
      
      let item = this.form[group][day][dayItem][k];
      if (!isNaN(item.id) && item.id > 0) {
        
        let data = {id: item.id};
        let $this = this;
        ApiService.postSecured("timeTable/deleteItem", data)
            .then(function () {
              //alert("Данные сохранены");
              
              $this.filterData();
            })
            .catch(({response}) => {
              console.log(response);
            });
      }


      this.reDrawTable = Math.random();
    },
    createByMoveItemModal(lesson, groupId) {
      this.currentGroup = groupId;
      this.createByMoveItemLesson = lesson;
      this.createByMoveItem = {
        groupId: groupId,
        day: 1,
        dayItem:1,
        teacherName: lesson.teacherName,
        roomNumber: lesson.roomNumber,
        subjectName: lesson.text,
        isAdditional: lesson.isAdditional
      };

      this.reDrawTable = Math.random();
      
      this.$refs["createByMoveItem-modal"].show();
    },
    /**/
    editItem(group, day, dayItem, k) {
      this.moveItem = {
        groupId:group,
        day:day,
        dayItem:dayItem,
        index:k,
        prevDay:day,
        prevDayItem:dayItem
      }

      this.moveItem['subjectName'] = this.subjectNames[this.form[group][day][dayItem][k].subjectId];
      this.moveItem['teacherName'] = this.form[group][day][dayItem][k].teacherName;
      this.moveItem['roomNumber'] = this.form[group][day][dayItem][k].roomNumber;
      this.moveItem['isAdditional'] = this.form[group][day][dayItem][k].isAdditional;
      
      this.$refs["moveItem-modal"].show();
    },
 
    pickItem(group, day, dayItem, item) {
      this.form[group][day][dayItem].push({
        subjectId: item.value,
        roomNumber: item.roomNumber,
        teacherName: item.teacherName,
      });
      
      this.usedItems[group][item.value] = this.usedItems[group][item.value] + 1;
      
      this.reDrawTable = Math.random();
    },
    getSubjectsPerWeekByGroup(groupId){
      let ret = [];
      for (let i in this.subjectsPerWeekByGroup[groupId]){
        let item = this.subjectsPerWeekByGroup[groupId][i];

        let cc = item.perWeekCount - this.usedItems[groupId][item.value];
        if(cc > 0){
          for (let i=0; i<cc; i++){
            ret.push(item)
          }
        }
      }

      return ret;
    },
    dragStart(event){
      if(this.editMode == false){
        return;
      }
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('itemID',  event.target.id)
    },
    allowDrop:function(event) {
      event.preventDefault();
    },
    drop:function(event) {
      event.preventDefault();
      let id = event.dataTransfer.getData("itemID");
      let item = document.getElementById(id);
      let itemGroupId = item.getAttribute('group-id');
      let itemDay = item.getAttribute('day');
      let itemDayItem = item.getAttribute('day-item');
      let itemIndex = item.getAttribute('index');
      let isAdditional = item.getAttribute('is-additional');
      
      let target = event.target;

      let targetGroupId = target.getAttribute('group-id');
      let targetDay = target.getAttribute('day');
      let targetDayItem = target.getAttribute('day-item');
      
       this.itemMove(itemGroupId, itemDay, itemDayItem, 
           itemIndex, targetGroupId, targetDay, targetDayItem, isAdditional);    
    },
    moveButtonPressed(){
      this.$refs["moveItem-modal"].hide();
      
      this.itemMove(
          this.moveItem.groupId
          ,this.moveItem.prevDay
          ,this.moveItem.prevDayItem
          ,this.moveItem.index
          ,this.moveItem.groupId
          ,this.moveItem.day
          ,this.moveItem.dayItem
          ,this.moveItem.isAdditional
      );
      
      this.moveItem = {};
    },
    closeMoveModal(){

      this.$refs["moveItem-modal"].hide();
      this.moveItem = {};
    },
    
    itemMove(itemGroupId, itemDay, itemDayItem, itemIndex, targetGroupId, targetDay, targetDayItem, isAdditional){
      if(itemGroupId !== targetGroupId){
        return;
      }
      
      if(targetDay === itemDay && itemDayItem === targetDayItem ){
        return;
      }
      
      // this.form[targetGroupId][targetDay][targetDayItem]
      //     .push(this.form[itemGroupId][itemDay][itemDayItem][itemIndex]);
      //
      // this.form[itemGroupId][itemDay][itemDayItem].splice(itemIndex, 1);

      let $this = this;
      let item = this.form[itemGroupId][itemDay][itemDayItem][itemIndex];
      let data = {
        id:item.id,
        subjectId:item.subjectId,
        dayOfWeek:targetDay,
        lessonNumber:targetDayItem,
        date:this.filter.date,
        isAdditional:isAdditional,
      }
      $this.loading = true;
      ApiService.postSecured("timeTable/saveItem", data)
          .then(function () {
            //alert("Данные сохранены");
            $this.loading = false;
            
            $this.filterData();
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    /**/
    filterData() {
      let $this = this;
      this.loading = true;
      this.filter.groupId = this.$route.params.id;
      
      ApiService.postSecured("timeTable/getForGroup", this.filter).then(({data}) => {
        console.log(data);
        for (let i in data.list) {
          $this.subjectsPerWeekByGroup[data.list[i].group.id] = data.list[i].subjectsPerWeek;

          for (let sPWIndex in data.list[i].subjectsPerWeek){
            let sPW = data.list[i].subjectsPerWeek[sPWIndex];
            $this.colors[sPW.value] = sPW.color;
          }

          $this.form[data.list[i].group.id] = {
            1: {},
            2: {},
            3: {},
            4: {},
            5: {},
            6: {}
          };

          $this.usedItems[data.list[i].group.id] = {};
          
          for (let ii in $this.form[data.list[i].group.id]) {
            $this.form[data.list[i].group.id][ii] = {};
            for (let iii in $this.dayItems) {
              $this.form[data.list[i].group.id][ii][$this.dayItems[iii]] = []
            }
          }

          for (let subjectsIndex in data.list[i].subjects) {
            let item = data.list[i].subjects[subjectsIndex];
            $this.subjectNames[item.value] = item.text;

            $this.usedItems[data.list[i].group.id][item.value] = 0;
          }
          
          try{
            for (let lessonIndex in data.list[i].lessons) {
              let lesson = data.list[i].lessons[lessonIndex];
              $this.form[data.list[i].group.id][lesson.dayOfWeek][lesson.lessonNumber].push({
                subjectId: lesson.subjectId,
                roomNumber: lesson.roomNumber,
                teacherName: lesson.teacherName,
                id: lesson.id,
                isAdditional: lesson.isAdditional
              });

              if(lesson.isAdditional !== true){
                $this.usedItems[data.list[i].group.id][lesson.subjectId] =
                    $this.usedItems[data.list[i].group.id][lesson.subjectId] + 1;
              }
            }
          }catch (e){
            console.log(e);
          }
          
          $this.subjects = data.list[i].subjects;
        }

        $this.data = data.list;
        $this.dates = data.dates;  
        
        $this.reDrawTable = Math.random();
        $this.loading = false;
      }).catch((response) => {
        console.log(response);
      });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values  
      //this.form = {};
      this.$refs["createItem-modal"].hide();
    },
    forceReDraw() {
      this.reDrawTable = Math.random();
    },
    onSubmit() {
      this.loading = true;
      
      let date = this.filter.date;
      let toSave = [];
      for (let ggroupIndex in this.form) {
        let groupData = this.form[ggroupIndex];
        for (let dayIndex in groupData) {
          let dayData = groupData[dayIndex];
          for (let slotIndex in dayData) {
            let slotData = dayData[slotIndex];
            if (slotData.length > 0) {
              let item = {
                dayOfWeek: parseInt(dayIndex),
                LessonNumber: parseInt(slotIndex),
                date: date,
                subjectIds: slotData
              };

              toSave.push(item)
            }
          }
        }
      }

      let data = {
        toSave: toSave,
        toDelete: this.toDelete,
      }
  
      let $this = this;
      ApiService.postSecured("timeTable/save", data)
          .then(function () {
            alert("Данные сохранены");
            $this.loading = false;
            $this.editMode = false;

            $this.filterData();
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    reloadData() {
    },
    changeDate() {
      this.filterData();
    },
    copyFromPrevWeek(groupId) {
      this.loading = true;
      let data = {
        groupId:groupId,
        date:this.filter.date,
      }
      let $this = this;
      ApiService.postSecured("timeTable/copyTimeTableFromPrevWeek", data)
          .then(function () {
            $this.filterData();            
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    copyFromNextWeek(groupId) {
      this.loading = true;
      
      let data = {
        groupId:groupId,
        date:this.filter.date,
      }
      let $this = this;
      ApiService.postSecured("timeTable/copyTimeTableFromNextWeek", data)
          .then(function () {
            $this.filterData();
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    showCopyButtonFromPrevWeek(groupId) {
      for (let day in this.days){
        for (let dayItem in this.dayItems){
          if(this.form[groupId][this.days[day]][this.dayItems[dayItem]].length > 0){
            return false; 
          }
        }
      }
      
      return true;
    },
    createByMoveButtonPressed(){
/*      this.pickItem(this.currentGroup, 
          this.createByMoveItem.day, 
          this.createByMoveItem.dayItem,
          this.createByMoveItemLesson
      );*/

      let $this = this;
      let data = {
        id:0,
        subjectId:this.createByMoveItemLesson.value,
        dayOfWeek:this.createByMoveItem.day,
        lessonNumber:this.createByMoveItem.dayItem,
        date:this.filter.date
      }
      $this.loading = true;
      ApiService.postSecured("timeTable/saveItem", data)
          .then(function () {
            //alert("Данные сохранены");
            $this.loading = false;
            //$this.editMode = false;

            $this.filterData();
          })
          .catch(({response}) => {
            console.log(response);
          });
      
      
      
      this.$refs["createByMoveItem-modal"].hide();
    },
    closeCreateByMoveModal(){

      this.$refs["createByMoveItem-modal"].hide();
      this.createByMoveItem = {};
      
      this.createByMoveItemLesson = null;
    },
    cancelEdit(){
      this.editMode = false;
    },
    enableEditMode(){
      this.editMode = true;
    },
    /*additional lesson*/
    additionalLesson(){
      this.createAdditional = {};
      this.$refs["createAdditionalLesson-modal"].show();
    },
    closeCreateAdditionalModal(){
      this.createAdditional = {};
      this.$refs["createAdditionalLesson-modal"].hide();
    },
    createAdditionalButtonPressed(){
      
      this.$refs["createAdditionalLesson-modal"].hide();
      
      let data = {
        subjectId:this.createAdditional.subjectId,
        dayOfWeek:this.createAdditional.day,
        lessonNumber:this.createAdditional.dayItem,
        date:this.filter.date,
        isAdditional: true,
      }
      
      let $this = this;
      $this.loading = true;
      ApiService.postSecured("timeTable/saveItem", data)
          .then(function () {
            //alert("Данные сохранены");
            $this.loading = false;
            $this.createAdditional = {};
            $this.filterData();
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    /**/
    showForAdmin(){
      return this.currentUser.role == 'ROLE_SCHOOL_ADMIN';
    },
  }
};
</script>
